import React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  screen,
  mainWhite,
  lightGrey,
  mainBlack,
  mainYellow,
} from "../components/common/variables";
import { BsArrowLeft } from "react-icons/bs";
import banner from "../images/group-sydney-street-choir.png";
import SubBanner from "../components/banner/sub";

const Wrapper = styled.div`
  .banner {
    position: relative;
    background: ${lightGrey};
    border-top: 22px ${mainWhite} solid;
    @media ${screen.xsmall} {
      border-top: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-top: 17px ${mainWhite} solid;
    }

    .inner-wrapper {
      .featured-txt {
        text-align: center;
        @media ${screen.small} {
          text-align: left;
        }

        .inner-txt {
          .capital-heading {
          }

          .red-heading {
            margin: 10px 0 0 0;
            @media ${screen.xsmall} {
              margin: 20px 0 0 0;
            }
          }

          .back-link {
            color: ${mainBlack};
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px 0 0 0;
            font-size: 0.88rem;
            letter-spacing: 2px;
            text-align: center;
            @media ${screen.xsmall} {
              margin: 20px 0 0 0;
            }
            @media ${screen.small} {
              justify-content: flex-start;
            }
            @media ${screen.withCursor} {
              &:hover {
                color: ${mainYellow};
              }
            }
            svg {
              font-size: 2rem;
              margin: 0 10px 0 0;
            }
          }
        }
      }
    }

    .bg {
      order: -1;
      @media ${screen.small} {
        order: 0;
      }
    }
  }
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404 Page Not Found" noIndex />
      <Wrapper>
        <SubBanner className="banner" bgUrl={banner}>
          <div className="inner-wrapper">
            <div className="featured-txt">
              <div className="inner-txt">
                <h1 className="capital-heading">
                  SORRY, WE COULDN'T
                  <br /> FIND THAT PAGE
                </h1>
                <p className="red-heading">404 ERROR</p>
                <Link className="back-link" to="/">
                  <BsArrowLeft />
                  BACK TO HOME
                </Link>
              </div>
            </div>
          </div>
        </SubBanner>
      </Wrapper>
    </Layout>
  );
};

export default NotFoundPage;
